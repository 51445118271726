import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/HalvaWithOzon"
import { Banner as RoundedDynamicBanner } from "../../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRefinancing } from "../../components/MoreBenefitsRefinancing"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../../helpers/getDynamicBannerUrl"

import { PageData } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { BottomCta, RefinRedesign } from "../../components/BottomCta"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const GIFT_TITLE = (
  <>
    Оформите карту «Халва»
    <br />
    и&nbsp;получите&nbsp;стикер&nbsp;PAY
    <br />
    <b>бесплатно</b>
  </>
)

const bannerTitle = `
24 месяца рассрочки
`
const bannerDescription = `
на покупки в маркетплейсах
`

// Время для таймер(30мин)
const countDownTime = 1800000

// TODO: Пока Новую длинную заявку надо показывать только на тесте + там он еще пройдет через АБ тест
// пока поставлю переменную только, придется поменять на тип того - globalThis.KameleeonExperiment(потом)

const partnersForMediaMarketPlace = [
  {
    id: 31,
    brand: "lamoda",
    urlName: "lamoda",
    icon_url: "https://api.halvacard.ru/public-api/files/1c06c068-daac-42e9-977a-62c7f11e1f56.jpg",
  },
  {
    id: 32,
    brand: "yandex-market",
    urlName: "yandex-market",
    icon_url: "https://api.halvacard.ru/public-api/files/6287d5ab-6607-49a6-9b94-edce6c0e9b73.jpeg",
  },
  {
    id: 33,
    brand: "ozon",
    urlName: "ozon",
    icon_url: "https://api.halvacard.ru/public-api/files/24983b4b-9d5e-40c4-8dd6-6349d436eec4.jpeg",
  },
  {
    id: 34,
    brand: "mega-market",
    urlName: "mega-market",
    icon_url: "https://api.halvacard.ru/public-api/files/3fab8dd8-dfba-4e98-95a1-cbdd8ecdac8e.jpeg",
  },
  {
    id: 35,
    brand: "lukoil",
    urlName: "lukoil",
    icon_url: "https://api.halvacard.ru/public-api/files/19e32f63-46aa-485f-9eb0-77cdb152b4d5.png",
  },
  {
    id: 36,
    brand: "sokolov",
    urlName: "sokolov",
    icon_url: "https://api.halvacard.ru/public-api/files/8588a516-0076-43e6-a1b7-60b614c515fc.png",
  },
]

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          orderNum="1"
        />
      )}
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        withTitle
        indent
        variant="redisignBezProcentov"
        orderNum="2"
      />
      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        backgroundColor="#EBECFF"
        orderNum="3"
      />
      <HowInstallmentWorks
        variant="redisignBezProcentov"
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        orderNum="4"
      />
      <NewCalculator
        titleLocationBottom
        subtext=""
        subtitle=""
        additionalEventInDataLayer
        orderNum="5"
      />
      <MoreBenefitsRefinancing additionalEventInDataLayer bottomBlock={false} orderNum="6" />
      <Partners orderNum="7" additionalPartners={partnersForMediaMarketPlace} />
      <BottomCta
        variant="refinRedesign"
        title={GIFT_TITLE}
        BgImg={RefinRedesign}
        orderNum="8"
        StickerPay
      />
      <SwitcherTextBlock mode="bezProcentov" orderNum="9" seoBlockText="" indent />
      <Reviews additionalEventInDataLayer orderNum="10" />

      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/media-marketplace/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
