// extracted by mini-css-extract-plugin
export var btn = "banner-module--btn--hnr1l";
export var btnBlock = "banner-module--btnBlock--in4g0";
export var container = "banner-module--container--WlhP5";
export var hideMobBlock = "banner-module--hideMobBlock--fj0cA";
export var img = "banner-module--img--DesRu";
export var mobBtn = "banner-module--mobBtn--sdPKK";
export var red = "banner-module--red--3+Nn8";
export var secondContainer = "banner-module--secondContainer--7EV++";
export var section = "banner-module--section--lRCfZ";
export var text = "banner-module--text--kHmPe";