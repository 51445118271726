import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerProps } from "../helpers"

const defaultTitle = `
Рефинансирование кредитных карт с Халвой
`

const defaultSubtext = `
— До 17% на остаток по карте
— Кэшбэк до 10%
— Обслуживание 0 ₽
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultSubtext,
  orderNum,
}: TypeBannerProps) {
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
                {buttonText}
              </Button>
            </div>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container>
          <Button onClick={() => handleClickBanner("click_cta")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
